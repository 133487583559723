import { useContext, createContext } from "react";
import { useNavigate } from "react-router-dom";
import  secureLocalStorage  from  "react-secure-storage";
import { fetchAPI } from '../functions/fetchAPI';

const AuthContext = createContext();
const AuthProvider = ({ children }) => {

  const navigate = useNavigate();
  
  const loginAction = async (data) => {
    try {
      const response = await fetchAPI('/api.php/user/login', 
        'POST', 
        data
      );
      const res = await response;

        if (res) {
          if(res.status==='error') {
            return { error: true, message: res.message };
          } else {
            secureLocalStorage.setItem("user", JSON.stringify({
                            UserId: res.Users_Id,
                            TenantId: res.Users_TenantId,
                            FirstName: res.Users_FirstName,
                            LastName: res.Users_LastName,
                            Email: res.Users_Email, 
                            Base64: res.UsersAvatar_Base64,
                            MimeType: res.UsersAvatar_MimeType,
                            FileName: res.UsersAvatar_FileName,
                            PortalRoleId: res.Users_PortalRoleId,
                            PortalRoleCode: res.RolePortal_Code,
                            PortalRoleName: res.RolePortal_Name
                           }));
            navigate("/dashboard/main");
            return { error: false, message: res.message };
          }
        }

      
      throw new Error(res.message);
    } catch (err) {
      return { error: true, message: err };
    };
  };

  const logOut = () => {
    secureLocalStorage.removeItem("user");
    navigate("/login");
  };

  return (
    <AuthContext.Provider value={{ loginAction, logOut }}>
      {children}
    </AuthContext.Provider>
  );

};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};