import { useState } from 'react';
import { useAuth } from '../../hooks/AuthProvider';
import LoadingButton from '@mui/lab/LoadingButton';
import { Form } from "react-bootstrap";
import DocumentTitle from '../../functions/setDocumentTitle';
import SnackbarAlert from '../../components/SnackbarAlert';


const Login = () => {

  /** Loading  */
  const [loading, setLoading] = useState(false);
  /**/

  /* Authentication */
  const auth = useAuth();
  /**/

  /* Alert */
  const [alert, setAlert] = useState({
    open: false,
    message: '',
    alert: ''
  });
  /**/

  /* Form validation/submit */
  const [validated, set_Validated] = useState(false);
  const [form_Data, set_Form_Data] = useState({
    email: "",
    password: ""
  });
  const submitFn = (event) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setLoading(true);
      event.preventDefault();
      auth
        .loginAction(form_Data)
          .then((response) => {
            if (response.error) {
              setAlert({ open: true, message: response.message, type: 'error' });
            }
          })
          .finally(() => {
            setLoading(false); 
          });
    }

    set_Validated(true);
    setAlert({ open: false, type: alert.type, message: alert.message });
  };
  const chngFn = (event) => {
    const { name, value } = event.target;
    set_Form_Data({
      ...form_Data,
      [name]: value,
    });
  };
  /**/

  return (
    <>
    {DocumentTitle("Login")}
    <div className="login-page">
      <div className="login-box">
        <SnackbarAlert open={alert.open} message={alert.message} type={alert.type} />
        {/* /.login-logo */}
        <div className="card card-outline card-primary">
          <div className="card-header text-center">
            <img src="dist/img/gestelev-logo-blue.png" width={"150px"} alt="" />
          </div>
          <div className="card-body p-5">
            <p className="login-box-msg">Iniciar sessão no portal</p>
            <Form
              noValidate
              validated={validated}
              onSubmit={submitFn}
              method="post"
            >
              <div className="input-group mb-3">
                <Form.Control
                  type="text"
                  name="email"
                  className="form-control"
                  placeholder="Email"
                  value={form_Data.email}
                  onChange={chngFn}
                  required
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-user" />
                  </div>
                </div>
                <Form.Control.Feedback type="invalid">
                  O Email é obrigatório!
                </Form.Control.Feedback>
              </div>

              <div className="input-group mb-3">
                <Form.Control
                  type="password"
                  name="password"
                  className="form-control"
                  placeholder="Password"
                  value={form_Data.password}
                  onChange={chngFn}
                  required
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock" />
                  </div>
                </div>
                <Form.Control.Feedback type="invalid">
                  A Password é obrigatória!
                </Form.Control.Feedback>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="icheck-primary">
                    <input type="checkbox" id="remember" />
                    <label htmlFor="remember">Lembrar dados</label>
                  </div>
                </div>
                {/* /.col */}
              </div>
              <div className="social-auth-links text-center mt-4 mb-3">
                <LoadingButton
                  loading={loading}
                  size="large"
                  variant="contained"
                  type="submit"
                  className="btn btn-primary btn-block"
                >
                  Entrar
                </LoadingButton>
              </div>
            </Form>
          </div>
          {/* /.card-body */}
        </div>
        {/* /.card */}
      </div>
      {/* /.login-box */}
    </div>
    </>
  );
};

export default Login;