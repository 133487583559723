import getLocalData from "./getLocalData";

export const fetchAPIEnctype = (endpoint,body,header) => {

    body.append('Log', JSON.stringify({
        UsersId: getLocalData("user", "UserId"),
        TenantId: getLocalData("user", "TenantId"),
        IsPortal: 1
    }));

    const headers = {
      ...header,
      "Authorization": "Basic " + btoa("gestelevapiuser:Luskas#1981")
    }

    return fetch(process.env.REACT_APP_API_DOMAIN+endpoint, {
        method: "POST",
        headers: headers,
        body: body
    })
      .then((res) => res.json()).then((responseData) => {
        return responseData;
      })
  
  }